<template>
  <div class="row">
    <div class="col">
      <el-checkbox v-model="jp_check.big" label="BIG" @change="check_verefy"/>
    </div>
    <div class="col">
      <el-checkbox v-model="jp_check.super" :disabled="!jp_check.big" label="SUPER" @change="check_verefy" />
    </div>
    <div class="col">
      <el-checkbox v-model="jp_check.mega" :disabled="!jp_check.super" label="MEGA" @change="check_verefy" />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <JackpotOneEdit v-if="jp_check.big" type="BIG" v-model="jp_data.big" :validerr="validerr_b" :color="color.big"></JackpotOneEdit>
    </div>
    <div class="col">
      <JackpotOneEdit v-if="jp_check.super" type="SUPER" v-model="jp_data.super" :validerr="validerr_s" :color="color.super"></JackpotOneEdit>
    </div>
    <div class="col">
      <JackpotOneEdit v-if="jp_check.mega" type="MEGA" v-model="jp_data.mega" :validerr="validerr_m" :color="color.mega"></JackpotOneEdit>
    </div>
  </div>

  <button @click="Apply()" class="btn-primary m-1">{{ $t('app.public.apply') }}</button>
  <button @click="Cancel()" class="btn-danger m-1">{{ $t('app.public.cancel') }}</button>
</template>

<script setup>
import {inject, onMounted, ref, computed, provide, watch, watchEffect, defineProps, defineEmits} from "vue";
import {useStore} from "vuex";
import JackpotOneEdit from "./JackpotOneEdit.vue";
import JackpotService from "@/services/jackpot.service";

const props = defineProps({
  jps: Object,
  color: Object
});

const deepClone = inject('deepClone')

const emit = defineEmits(['update:jps'])

const $t = inject('$t')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const set_result = inject('set_result')

const sel_row = ref(null)

const public_setting = inject('public_setting')
const game_setting = inject('game_setting')

const jp_check = inject('jp_check')
const jp_data = ref({big: {}, super: {}, mega: {}})

const selOneBillInfo = inject('selOneBillInfo')

function changeProps()
{
  jp_data.value = deepClone(props.jps)
}
watchEffect(changeProps)

const set_save_active = inject('set_save_active')

const color_off = inject('color_off')

function claerColorChange(type)
{
  color_off(type, 'description')
  color_off(type, 'contribution_percentage')
  color_off(type, 'lower_threshold')
  color_off(type, 'upper_threshold')
  color_off(type, 'min_bet_amount')
}

function check_verefy()
{
  let next=true

  for(let key in jp_check.value) {
    let cur = jp_check.value[key]

    if (cur!=true) {
      claerColorChange(key)
    }

    if (next==true && cur!=true) {
      next=false
    }

    if(next!=true) {
      jp_check.value[key]=false
    }
  }
}

const applyJPData = inject('applyJPData')

const validatas = inject('validatas')

const validerr_b = inject('validerr_b')
const validerr_s = inject('validerr_s')
const validerr_m = inject('validerr_m')
const verdata = inject('verdata')

const data = ref({
  big: {
    'jackpot_type': 'BIG',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': ''
  },
  super: {
    'jackpot_type': 'SUPER',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': ''
  },
  mega: {
    'jackpot_type': 'MEGA',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': ''
  }
})

function Apply()
{
  validerr_b.value = new Map([])
  validerr_s.value = new Map([])
  validerr_m.value = new Map([])

  if(jp_check.value.big==true) {
    validerr_b.value = validatas(jp_data.value.big, verdata.value)
  }

  if(jp_check.value.super==true) {
    validerr_s.value = validatas(jp_data.value.super, verdata.value)
  }

  if(jp_check.value.mega==true) {
    validerr_m.value = validatas(jp_data.value.mega, verdata.value)
  }

  if(validerr_b.value.size>0 || validerr_s.value.size>0 || validerr_m.value.size>0) {
    return
  }

  let jd_data_save = {}
  for(let keyjp in jp_data.value) {
    let curjp =  jp_data.value[keyjp]

    if(jp_check.value[keyjp]==true) {
      jd_data_save[keyjp] = curjp
    }
  }

  applyJPData(jd_data_save)

  game_setting.value=null
  public_setting.value=false

  set_save_active(true)
}

function Cancel()
{
  game_setting.value=null
  public_setting.value=false
}

// function getTemp()
// {
//   JackpotService.getAllJPTemplate(selOneBillInfo.value.currency).then(
//       (response) => {
//         for(let key in response.data) {
//           let cur = response.data[key]
//
//           if (cur.jackpot_status == 'active') {
//             cur.jackpot_status = true
//           } else {
//             cur.jackpot_status = false
//           }
//           const jackpotType = cur.fid_jackpot_type.toLowerCase()
//
//           if (data.value[jackpotType]) {
//             data.value[jackpotType].description = cur.description
//             data.value[jackpotType].contribution_percentage = cur.contribution_percentage
//             data.value[jackpotType].lower_threshold = cur.lower_threshold
//             data.value[jackpotType].upper_threshold = cur.upper_threshold
//             data.value[jackpotType].min_bet_amount = cur.min_bet_amount
//             data.value[jackpotType].jackpot_status = cur.jackpot_status
//           }
//         }
//       },
//       (error) => {
//         set_result(error.response.status, error)
//       }
//   )
// }
//

function setCheck()
{
  for(let key in jp_data.value) {
    let cur = jp_data.value[key]

    if (Object.keys(cur).length>0) {
      jp_check.value[key] = true
    } else {
      jp_check.value[key] = false
    }
  }
}

function mounted_fn()
{
  setCheck()
}

onMounted(mounted_fn)
</script>
