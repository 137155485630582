<template>
  <div>
    <div class="row p-1">
      <div class="row hyperlink">
        <div class="col">
        </div>
      </div>
      <div class="row hyperlink">
        <div class="col"><p v-if="showTmpl" @click="getTmepl">{{ $t('billmanager.billmanagerjackpotsedit.add_from_template') }}</p></div>
      </div>
    </div>
    <label v-if="isPubParent==true && public_setting!=true"><input type="checkbox" @change="change_global" v-model="data.is_global"> {{ $t('billmanager.billmanagerjackpotsedit.isGlobal') }}</label>
    <div v-if="data.is_global==true" class="row p-1">
      <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.parent') }}</div>
      <div class="col">
        <el-select v-model="data.parent" filterable @change="parentChange">
          <el-option
              v-for="item in parent_list"
              :key="item.id"
              :label="item.text"
              :value="item.id"
          />
        </el-select>
        <span v-if="validerr.get('parent')" class="validerr">{{validerr.get('parent')}}</span>
      </div>
    </div>
    <div class="row p-1">
      <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.description') }}</div>
      <div class="col">
        <textarea
            :class="color.description"
            @change="changeField('description')"
            @keyup="changeField('description')"
            v-model="data.description"
        ></textarea>
        <span v-if="validerr.get('description')" class="validerr">{{validerr.get('description')}}</span>
      </div>
    </div>
    <div>
      <div class="row p-1">
        <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.contributionpercentage') }}</div>
        <div class="col">
          <input :class="color.contribution_percentage"
                 @change="changeField('contribution_percentage')"
                 @keyup="changeField('contribution_percentage')"
                 type="number"
                 step='0.01'
                 max="100"
                 :disabled="data.is_global==true"
                 v-model="data.contribution_percentage">
          <span v-if="validerr.get('contribution_percentage')" class="validerr">{{validerr.get('contribution_percentage')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.lowerthreshold') }}</div>
        <div class="col">
          <input
              :class="color.lower_threshold"
              @change="changeField('lower_threshold')"
              @keyup="changeField('lower_threshold')"
              type="number"
              min="0"
              :disabled="data.is_global==true"
              v-model="data.lower_threshold">
          <span v-if="validerr.get('lower_threshold')" class="validerr">{{validerr.get('lower_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.upperthreshold') }}</div>
        <div class="col">
          <input
              :class="color.upper_threshold"
              @change="changeField('upper_threshold')"
              @keyup="changeField('upper_threshold')"
              min="0"
              type="number"
              :disabled="data.is_global==true"
              v-model="data.upper_threshold">
          <span v-if="validerr.get('upper_threshold')" class="validerr">{{validerr.get('upper_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-5">{{ $t('billmanager.billmanagerjackpotsedit.minbetamount') }}</div>
        <div class="col">
          <input
              :class="color.min_bet_amount"
              @change="changeField('min_bet_amount')"
              @keyup="changeField('min_bet_amount')"
              type="number"
              min="0"
              :disabled="data.is_global==true"
              v-model="data.min_bet_amount">
          <span v-if="validerr.get('min_bet_amount')" class="validerr">{{validerr.get('min_bet_amount')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, inject, computed, watch, onMounted, watchEffect, defineProps, defineEmits} from "vue";
import {useStore} from "vuex";
import JackpotService from "@/services/jackpot.service";
import WizardsService from "../../../services/wizards.service";

const props = defineProps({
  modelValue: Object,
  type: String,
  validerr: Map,
  color: Object
});

const emit = defineEmits(['update:modelValue'])

const data = ref({
  is_global: false,
  jackpots_grade: props.type,
  description: '',
  contribution_percentage: '',
  lower_threshold: '',
  upper_threshold: '',
  min_bet_amount: ''
})

const game_setting = inject('game_setting')
const public_setting = inject('public_setting')

function changeData(data_new)
{
  emit('update:modelValue', data_new)
}
watch(data.value, changeData)

const store = useStore()

const $t = inject('$t')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const currentBillInfo = inject('selOneBillInfo')

const changeGlobal = ref(0)

const oldShowHideTree = ref(false)
const showHideTree = inject('showHideTree')

const maskpercent={ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9.,]/ }}}

const set_result = inject('set_result')

const SelOneBill = inject('SelOneBill')
const SelBills = inject('SelBills')

const jackpot_id = ref()

const JPTmpl = ref({})

const parent = ref('')
const parent_list = ref([])

const isPubParent = inject('isPubParent')

function getJackpotsForParents()
{
  JackpotService.getJackpotsForParents(props.type, SelBills.value[0], game_setting.value, SelBills.value, 2).then(
      (response) => {
        parent_list.value=response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getDataParent(jackpot_id, bill_id)
{
  JackpotService.get(jackpot_id, bill_id).then(
      (response) => {
          data.value.description=response.data.description
          data.value.contribution_percentage=response.data.contribution_percentage,
          data.value.lower_threshold=response.data.lower_threshold,
          data.value.upper_threshold=response.data.upper_threshold,
          data.value.current_accumulation=response.data.current_accumulation,
          data.value.min_bet_amount=response.data.min_bet_amount

        changeField('contribution_percentage')
        changeField('lower_threshold')
        changeField('upper_threshold')
        changeField('min_bet_amount')
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function parentChange(id)
{
  getDataParent(id)
}

const color_off = inject('color_off')
function changeField(field)
{
  color_off(props.type.toLowerCase(), field)
}

function showTmpl_fn()
{
  let res=false

  if(JPTmpl.value!=undefined && Object.keys(JPTmpl.value).length>0) {
    res=true
  }

  return res
}
const showTmpl=computed(showTmpl_fn)

function getTmepl()
{
  let cur = JPTmpl.value

  if (cur.jackpot_status=='active') {
    data.value.jackpot_status = true
  }

  if (data.value.description==null || !data.value.description.trim()) {
    data.value.description = cur.description;
  }

  data.value.contribution_percentage=cur.contribution_percentage
  data.value.lower_threshold=cur.lower_threshold
  data.value.upper_threshold=cur.upper_threshold
  data.value.min_bet_amount=cur.min_bet_amount
}

function getJPTmpl()
{
  let grade = props.type
  let currency = currentBillInfo.value.currency

  if (currency!=undefined) {
    JackpotService.getJPTmpl(grade, currency).then(
        (response) => {
          JPTmpl.value=response.data
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}

function setData()
{
  if(props.modelValue!=undefined && Object.keys(props.modelValue).length) {
    data.value = props.modelValue
  }
}

const prev_lower_threshold=ref('')

function change_global()
{
  if(data.value.is_global==false) {
    WizardsService.publicJPData(SelBills.value, game_setting.value, data.value.jackpots_grade).then(
      (response) => {
        let cur = response.data

        data.value.description=''
        data.value.contribution_percentage=cur.contribution_percentage
        data.value.lower_threshold=cur.lower_threshold
        data.value.upper_threshold=cur.upper_threshold
        data.value.min_bet_amount=cur.min_bet_amount

        changeField('description')
        changeField('contribution_percentage')
        changeField('lower_threshold')
        changeField('upper_threshold')
        changeField('min_bet_amount')
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function mounted_fn()
{
  oldShowHideTree.value=showHideTree.value

  getJPTmpl()

  setData()

  getJackpotsForParents()
}

onMounted(mounted_fn)
</script>

<style>
.hyperlink {
  color: #007BFF;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink:hover {
  color: #0056b3;
}

.yellow {
  background: yellow;
}
</style>
