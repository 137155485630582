<template>
  <p></p>
  <div v-if="!SelBillsOneCur && !diferentCur" class="container-fluid">{{ $t('wizards.changegame.changegame.pleaseSelectAt') }}</div>
  <div v-if="diferentCur" class="container-fluid">{{ $t('wizards.changegame.changegame.diferentCur') }}</div>
  <div v-if="SelBillsOneCur && !diferentCur" class="container-fluid">
    <el-checkbox
        v-if="false && game_setting==null"
        :label="$t('wizards.changejp.public_setting')"
        v-model="public_setting"/>

    <el-button v-if="game_setting==null && public_setting!=true" @click="toPublic">{{$t('wizards.changejp.public_setting')}}</el-button>

    <div v-if="public_setting==false && game_setting==null">
      <el-table
          v-if="games_list.length>0"
          :data="games_list"
          style="width: 100%"
          border
          :scrollbar-always-on="true"
          :row-class-name="tableRowClassName"
      >
        <el-table-column v-for="column in columns"
                         :key="column.field"
                         :prop="column.field"
                         :label="column.label"
                         :min-width="column.minwidth"
        >
          <template #default="scope" v-if="['type_check', 'jp', 'action'].includes(column.field)">
            <el-checkbox v-if="column.field=='type_check'"
              :indeterminate="select_check_imp['g'+scope.row.game_id]"
              v-model="select_check['g'+scope.row.game_id]"
              @change="checkclick(scope.row.game_id)"
            />

            <div v-if="column.field=='jp' && jp_data_fs_edit[scope.row.game_id]==true">
              <span
                  v-for="jackpot in jp_data_fs.games[scope.row.game_id]"
                  :class="jackpot.jackpots_grade+jackpot.is_global"
                  :key="jackpot"
                  class="jplist alert p-1"
              >
                {{jackpot.jackpots_grade}}
              </span>
            </div>

            <div v-if="column.field=='action'">
              <button @click="editJackpots(scope.row.game_id)" type="button" class="btn btn-primary p-1">
                <div class="icon-size">
                  <font-awesome-icon icon="pencil-alt" />
                </div>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="public_setting==true">
      <JPEdit :jps="jp_data_fs.pub" :color="jp_data_fs_color.pub"></JPEdit>
    </div>

    <div v-if="game_setting!=null">
      <div v-for="(item, key) in jp_data_fs.games" :key="key">
          <JPEdit v-if="game_setting==key" :jps="jp_data_fs.games[key]" :color="jp_data_fs_color.games[key]"></JPEdit>
      </div>
    </div>

    <p></p>
    <button v-if="game_setting==null && public_setting!=true" @click="Save()" :class="{blocked: save_active!=true}"  class="btn btn_save m-1">{{ $t('app.public.save') }}</button>
  </div>
</template>

<script setup>
import WizardsService from "../../../services/wizards.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import {computed, inject, onMounted, provide, ref, watch, watchEffect} from "vue";
import { getCurrentInstance } from 'vue'
import JPEdit from "./JPEdit"
import JackpotService from "../../../services/jackpot.service";

const $t = inject('$t')

const selBillsInfo = inject('selBillsInfo')
const selOneBillInfo = inject('selOneBillInfo')

const typew = ref('')
const sel_games = ref([])
const sel_game = ref('')

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const selOneBill = inject('SelOneBill')
const SelBills = inject('SelBills')
const SelBillsOneCur = ref(false)
const diferentCur = ref(false)

const deepClone = inject('deepClone')

const save_active = ref(false)
function set_save_active(data)
{
  save_active.value = data
}
provide('set_save_active', set_save_active)

const public_setting = ref(false)
provide('public_setting', public_setting)

const game_setting = ref(null)
provide('game_setting', game_setting)

function verefyPubParent()
{
  let res = false

  let unicParent = {}

  for(let key in selBillsInfo.value) {
    let cur = selBillsInfo.value[key]

    unicParent[cur.parent_id]=cur.parent_id
  }

  if(Object.keys(unicParent).length==1) {
    res = true
  }

  return res
}
const isPubParent = computed(verefyPubParent)
provide('isPubParent', isPubParent)

function tableRowClassName(row, rowIndex)
{
  if(jp_data_fs_edit.value[row.row.game_id]!=true) {
    return 'noedit'
  }

  return ''
}

const jp_data_fs_edit = ref({})
const jp_data_fs = ref({pub: {big: {}, super: {}, mega: {}}, games: {}})
const jp_data_fs_color = ref({pub: {big: {}, super: {}, mega: {}}, games: {}})
function color_off(type, field)
{
  if(public_setting.value==true) {
    jp_data_fs_color.value.pub[type][field]=null
  } else {
    jp_data_fs_color.value.games[game_setting.value][type][field]=null
  }
}
provide('color_off', color_off)

function fieldsTotal()
{
  let fld = []

  //fld.push({label: $t('wizards.changejp.type_check'), field: 'type_check', minwidth: '10'})
  fld.push({label: $t('wizards.changejp.game_id'), field: 'game_id', minwidth: '10'})
  fld.push({label: $t('wizards.changejp.game_nameid'), field: 'game_name'})
  fld.push({label: $t('wizards.changejp.jp'), field: 'jp'})
  fld.push({label: $t('app.action'), field: 'action'})

  return fld
}
const columns = computed(fieldsTotal)

const validerr_b = ref(new Map())
const validerr_s = ref(new Map())
const validerr_m = ref(new Map())
const verdata = ref(new Map([
  ['description', {reg: '.+'}],
  ['contribution_percentage', {reg: '^[0-9]{1,2}([.][0-9]{0,2})?$|^100$', 'mess': 'возможно внести только число от 0 до 100 с точностью до сотых'}],
  ['lower_threshold', {reg: '[0-9]+'}],
  ['upper_threshold', {reg: '[0-9]+'}],
  ['min_bet_amount', {reg: '[0-9]+'}],
]));

provide('validerr_b', validerr_b)
provide('validerr_s', validerr_s)
provide('validerr_m', validerr_m)
provide('verdata', verdata)

const jp_check = ref({big: true, super: true, mega: true})
provide('jp_check', jp_check)

const validatas = inject('validatas')

const games_list = ref([])

const select_check = ref({})
const select_check_imp = ref({})
const select_bills = ref({})

function editJackpots(id)
{
  if(jp_data_fs.value.games[id]==undefined) {
    jp_data_fs.value.games[id]={big: {}, super: {}, mega: {}}
  }

  game_setting.value=id
}

const jp_pub = ref({})

function prepare_jp_color(game_id, type, cur)
{
  for(let keyf in cur) {
    if(cur[keyf]!=null) {
      jp_data_fs_color.value.games[game_id][type][keyf] = 'yellow'
    }
  }
}

function prepare_jp_pub(type, cur)
{
  if(Object.keys(jp_pub.value[type]).length==0) {
    jp_pub.value[type]=cur.jp_data[type]
  }

  for(let keyf in cur.jp_data[type]) {
    if(jp_pub.value[type][keyf]!=cur.jp_data[type][keyf]) {
      jp_pub.value[type][keyf]=null
    }

    jp_data_fs_color.value.pub[type][keyf]=''
    if (jp_pub.value[type][keyf]!=null) {
      jp_data_fs_color.value.pub[type][keyf]='yellow'
    }
  }
}

function prepare_jp(cur)
{
  jp_data_fs.value.games[cur.game_id]={big: {}, super: {}, mega: {}}

  if(cur.jp_data['big']!=undefined) {
    jp_data_fs.value.games[cur.game_id]['big'] = cur.jp_data['big']
    prepare_jp_pub('big', deepClone(cur))
    prepare_jp_color(cur.game_id, 'big', cur.jp_data['big'])
  }

  if(cur.jp_data['super']!=undefined) {
    jp_data_fs.value.games[cur.game_id]['super'] = cur.jp_data['super']
    prepare_jp_pub('super', deepClone(cur))
    prepare_jp_color(cur.game_id, 'super', cur.jp_data['super'])
  }

  if(cur.jp_data['mega']!=undefined) {
    jp_data_fs.value.games[cur.game_id]['mega'] = cur.jp_data['mega']
    prepare_jp_pub('mega', deepClone(cur))
    prepare_jp_color(cur.game_id, 'mega', cur.jp_data['mega'])
  }
}

function select_check_fn()
{
  jp_pub.value = {big: {}, super: {}, mega: {}}

  jp_data_fs_color.value.games = {}

  for(let key in games_list.value) {
    let cur=games_list.value[key]

    jp_data_fs_color.value.games[cur.game_id] = {big: {}, super: {}, mega: {}}

    if(cur.jp_data!=null) {
      prepare_jp(cur)
    }
  }

  jp_data_fs.value.pub = jp_pub.value
}

function checkclick(data)
{
  select_check_imp.value['g'+data] = false

  if(select_check.value['g'+data]==false) {
    delete select_bills.value['g'+data]
  }

  if(select_check.value['g'+data]==true) {
    select_bills.value['g'+data]=[]
    for(let key in SelBills.value) {
      let cur=SelBills.value[key]
      select_bills.value['g'+data].push(parseInt(cur))
    }
  }
}

function gamesByBillList(bills, curr)
{
  WizardsService.gamesByBillList(bills, curr).then(
    (response) => {
      games_list.value = response.data;
      select_check_fn()
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function applyJPData(data)
{
  if(public_setting.value!=true) {
    jp_data_fs.value.games[game_setting.value]=data
    jp_data_fs_edit.value[game_setting.value]=true
  } else {
    jp_data_fs.value.pub = data

    for (let key in games_list.value) {
      let cur = games_list.value[key]

      jp_data_fs.value.games[cur.game_id]=data
      jp_data_fs_edit.value[cur.game_id]=true
    }
  }
}
provide('applyJPData', applyJPData)

function toPublic()
{
  public_setting.value = true
}

function changeBills()
{
  set_save_active(false)
  jp_data_fs_edit.value = {}

  game_setting.value = null;
  public_setting.value = false
}

function veryfyCur()
{
  changeBills()

  SelBillsOneCur.value=false
  diferentCur.value=false

  let prevCUR=''
  if(cntSelBill.value==1) {
    SelBillsOneCur.value=true
    let cur=selOneBillInfo.value
    prevCUR=cur['currency']
  }

  if(cntSelBill.value>1) {
    for(let key in selBillsInfo.value) {

      let cur=selBillsInfo.value[key]
      if(prevCUR!='' && prevCUR!=cur['currency']) {
        diferentCur.value=true
        return false
      }

      prevCUR=cur['currency']
    }
  }

  if(prevCUR!='' && prevCUR!=undefined) {
    SelBillsOneCur.value=true

    gamesByBillList(SelBills.value, prevCUR)
  }
}
watchEffect(veryfyCur)

function Save()
{
  if (save_active.value!=true) {
    return
  }

  let save_data = {}

  for(let key in games_list.value) {
    let cur = games_list.value[key]
    let dt = {}

    if(jp_data_fs.value.games[cur.game_id] != undefined
        && jp_data_fs_edit.value[cur.game_id]!=undefined
        && jp_data_fs_edit.value[cur.game_id]==true) {
      dt['jp'] = jp_data_fs.value.games[cur.game_id]
      dt['bills'] = cur.lll
      save_data[cur.game_id] = dt
    }
  }

  WizardsService.saveChangeJP(save_data).then(
      () => {
        set_result()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}
</script>

<style>
.el-table .noedit {
  --el-table-tr-bg-color: #CCCCCC;
}
</style>

<style scoped>
  .jplist {
    margin: 3px;
    color: black;
  }

  .jplist.BIGfalse {
    background-color: #cd7f32;
  }

  .jplist.SUPERfalse {
    background-color: silver;
  }

  .jplist.MEGAfalse {
    background-color: gold;
  }

  .jplist.BIGtrue {
    background-color: dodgerblue;
  }

  .jplist.SUPERtrue {
    background-color: #468847;
  }

  .jplist.MEGAtrue {
    background-color: aqua;
  }

  .btn_save {
    background-color: #1e7e34;
    color: white;
  }

  .btn_save.blocked {
    background-color: #999999;
    border-color: #606266;
  }
</style>