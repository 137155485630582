import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'jackpot/';

class JackpotService {
  save(bill_id, game_id, data, jackpot_id) {
    return axios.post(API_URLA + 'save/', {"bill_id": bill_id, "game_id": game_id, "data": data, "jackpot_id": jackpot_id});
  }
  get(jackpot_id, bill_id=null) {
    return axios.post(API_URLA + 'get/', {"jackpot_id": jackpot_id, "bill_id": bill_id});
  }
  getLocal(bill_id, game_id, grade_code) {
    return axios.post(API_URLA + 'getLocal/', {"bill_id": bill_id, "game_id": game_id, "grade_code": grade_code});
  }
  getJackpotsForParents(grade, bill, game, bills, lv=null) {
    return axios.post(API_URLA + 'getJackpotsForParents/', {"grade": grade, "bill": bill, "game": game, "bills": bills, "lv": lv});
  }
  saveTemplate(data) {
    return axios.post(API_URLA + 'saveTemplate/', {"data": data});
  }
  getAllJPTemplate(iso) {
    return axios.post(API_URLA + 'getTemplate/', {"iso": iso});
  }
  getJPTemplate(bill_id, jp_type, curr) {
    return axios.post(API_URLA + 'getOneTemplate/', {"bill_id": bill_id, "jp_type": jp_type, "curr": curr});
  }
  getJPTmpl(grade, currency) {
    return axios.post(API_URLA + 'getJPTmpl/', {"grade": grade, "currency": currency});
  }
}

export default new JackpotService();
